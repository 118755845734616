<template>
    <div class="candidate w-64 h-72 rounded p-4 shadow-card">
        <div class="info">
            <!-- <span>Info</span> -->
            <div class="info-section">
                <div class="grid-row-info">
                    <span class="label-span">Email</span>
                    <span class="email" @click="copyEmail"
                        >{{ candidate?.Email ? candidate?.Email : candidate?.candidate }} <img loading="lazy" decoding="async" src="../assets/Images/icons/copy-icon.svg" alt="copy-icon"
                    /></span>
                </div>
                <div class="grid-row-info">
                    <span class="label-span">{{ $t("Status") }}</span>
                    <span class="status" :class="passed ? 'passed' : 'failed'">{{ $t("Pending") }}</span>
                </div>
                <div class="grid-row-info">
                    <span class="label-span">{{ $t("Project") }}</span>
                    <span class="label-span">{{ getProjectName(candidate?.projectId) }}</span>
                </div>
                <div class="grid-row-info">
                    <span class="label-span">{{ $t("Assessment") }}</span>
                    <span class="label-span">Sample Assessment</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useStore } from "../store/index";

export default {
    name: "InvitedCandidatesCard",
    props: ["candidate", "candidateInfo", "passed", "backgroundHeader"],
    data() {
        return {
            showActivity: false,
        };
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    methods: {
        copyEmail() {
            const emailText = this.candidate.Email;
            const el = document.createElement("textarea");
            el.value = emailText;
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);
            alert("Email copied to clipboard!");
        },
        toggleActivity() {
            this.showActivity = !this.showActivity;
        },
        getDateAndTime(dateString) {
            const date = new Date(dateString);

            const year = date.getUTCFullYear();
            const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are zero-based, so we add 1
            const day = date.getUTCDate().toString().padStart(2, "0");
            const hours = date.getUTCHours().toString().padStart(2, "0");
            const minutes = date.getUTCMinutes().toString().padStart(2, "0");
            const seconds = date.getUTCSeconds().toString().padStart(2, "0");

            const formattedDateTime = `${year}-${month}-${day} ${parseInt(hours) + 1}:${minutes}:${seconds}`;
            return formattedDateTime;
        },
        getImgUrl(imgFileId) {
            if (imgFileId) {
                var image = `data:image/png;base64,${imgFileId}`;
                console.log({ image });
                return image;
            }
            return require("../assets/Images/candidate-image.png");
        },
        getProjectName(projectId) {
            const project = this.Store.projects.find((project) => project._id === projectId);
            return project?.name;
        },
    },
};
</script>

<style scoped lang="scss">
.candidate {
    background: #fff;

    border-radius: 12px;
    padding: 12px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    width: 100%;
    &:hover {
        transform: scale(1.02);
    }
}

.header {
    padding: 8px;
    filter: drop-shadow(0px 18px 40px rgba(112, 144, 176, 0.12));
    border-radius: 17px;
}

.header-background-1 {
    background: linear-gradient(135deg, #868cff 0%, #4318ff 100%, #2196f3 100%);
}
.header-background-2 {
    background: linear-gradient(135deg, #ffd133 0%, #4318ff 100%, #2196f3 100%);
}
.header-background-0 {
    background: linear-gradient(135deg, #00848b 7.08%, #067a96 35.73%, #4318ff 100%, #2196f3 100%);
}

.dots {
    display: block;
}

.profile {
    margin-top: -8px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 2%;
    gap: 0.5rem;
    flex-direction: row-reverse;
}

.profile-name {
    font-weight: 700;
    font-size: 28px;

    color: #fff;
}

.profile-image {
    max-width: 25%;
}

.grid-row-info {
    display: grid;
    grid-template-columns: 2fr 3fr;
    align-items: center;
    margin-bottom: 16px;
}

.info > span {
    display: block;
    font-weight: 500;
    font-size: 12px;
    color: #a3aed0;
    margin: 24px 0px;
}

.info-section {
    /* margin-left: 24px; */
    margin-top: 1.25rem;
}

.label-span {
    font-weight: 400;
    font-size: 13px;
    color: #343637;
    text-align: left;
}

.email {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 400;
    font-size: 13px;
    color: #2196f3;
}

.email > img {
    margin-left: 8px;
}

.status {
    width: fit-content;
    padding: 2px 12px;
    font-weight: 400;
    font-size: 13px;
    color: #f7f8f9;
    border-radius: 10px;
}

.passed {
    background: #05cd99;
}

.failed {
    background: #e62e7b;
}
</style>
